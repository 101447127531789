import React from "react";
import ReactDOM from "react-dom/client";

import TagManager from "react-gtm-module";

import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const isHarkinsAndVickers = window.location.href.includes("harkinsandvickers");

// configure and initialise Google Tag Manager
const tagManagerArgs = {
  gtmId: isHarkinsAndVickers
    ? "GTM-PLZS4RL"
    : "GTM-P4TXVZS", // assume Keith Gunn
}
TagManager.initialize(tagManagerArgs);

// configure Google Tag Manager script for body tag
let gtmScript;
if (isHarkinsAndVickers) {
  gtmScript = `<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-PLZS4RL"
    height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>`;
}
else {
  gtmScript = `<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-P4TXVZS"
    height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>`;
}

// Function to insert the GTM script into the body
function insertGtmScript(script) {
  const body = document.body;
  const div = document.createElement("div");
  div.innerHTML = script;
  // body.appendChild(div.firstChild);
  body.insertBefore(div.firstChild, body.firstChild);
}

// Insert the GTM script after the DOM has loaded
document.addEventListener("DOMContentLoaded", () => {
  insertGtmScript(gtmScript);
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
