import React from "react";
import { Helmet } from "react-helmet";

function MetaInfo(props) {
  const { url } = props;
  const isHarkinsAndVickers =
    window.location.href.includes("harkinsandvickers");

  let title, description, keywords, ogImage, favicon, gtmHeadSrcUrl, gtmScriptContents;
  if (isHarkinsAndVickers) {
    title = "Bookings - Harkins and Vickers Electrical Services";
    description = "This is the bookings page for Harkins and Vickers.";
    keywords = "harkins & vickers, bookings";
    ogImage = "/harkinsandvickerslogo.svg";
    favicon = "/hvfavicon.ico";
    // gtmScript = `
    //   <!-- Google tag (gtag.js) -->
    //   <script async src="https://www.googletagmanager.com/gtag/js?id=G-MJ55EKZYN6"></script>
    //   <script>
    //     window.dataLayer = window.dataLayer || [];
    //     function gtag(){dataLayer.push(arguments);}
    //     gtag('js', new Date());

    //     gtag('config', 'G-MJ55EKZYN6');
    //   </script>
    // `;
    gtmHeadSrcUrl = "https://www.googletagmanager.com/gtag/js?id=G-MJ55EKZYN6";
    // gtmHeadTagID = 'G-MJ55EKZYN6';
    gtmScriptContents = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', 'G-MJ55EKZYN6');
    `;
  } else { // assume Keith Gunn
    title = "Bookings - Keith Gunn Electrical Services";
    description = "This is the bookings page for KGES.";
    keywords = "keith gunn, bookings";
    ogImage = "/kgeslogo.png";
    favicon = "/kgesfavicon.ico";
    // gtmScript = `
    //   <!-- Google tag (gtag.js) -->
    //   <script async src="https://www.googletagmanager.com/gtag/js?id=G-JLN6F829PC"></script>
    //   <script>
    //     window.dataLayer = window.dataLayer || [];
    //     function gtag(){dataLayer.push(arguments);}
    //     gtag('js', new Date());

    //     gtag('config', 'G-JLN6F829PC');
    //   </script>
    // `;
    gtmHeadSrcUrl = "https://www.googletagmanager.com/gtag/js?id=G-JLN6F829PC";
    // gtmHeadTagID = 'G-JLN6F829PC';
    gtmScriptContents = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', 'G-JLN6F829PC');
    `;
  }

  return (
    <Helmet>
      <meta charset="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={url} />
      <meta property="og:image" content={ogImage} />
      {/* Add any other Open Graph meta tags you need */}
      <link rel="shortcut icon" href={favicon} type="image/x-icon" />
      {/* Add any other link tags you need */}
      <title>{title}</title>
      {/* Add any other meta tags you need */}
      {/* <script type="text/javascript">{gtmScript}</script> */}
      <script async src={gtmHeadSrcUrl}></script>
      <script>{gtmScriptContents}</script>
    </Helmet>
  );
}

export default MetaInfo;
