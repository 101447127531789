import React, { useContext, useEffect } from "react";
import { AppContext } from "../Main";
import Card from "../UI/Card";
import {
  CARBON_MONOXIDE,
  CONSUMER_UNIT,
  COULD_NOT_FIND_TIMESLOT,
  EICR,
  EXISTING_ALARMS_SELECTED,
  FOUR_BEDROOM_SELECTED,
  LED_LIGHTING,
  REQUESTED_EMAILED_QUOTE,
  REWIRING,
  SMOKE_DETECTOR,
  STAIR_LIGHTING,
  WRONG_LIGHT_FITTING,
} from "../../utils/utils";
const hvEicrHelpGuide =
  "/help-guides/harkins-and-vickers/HV-EICR-Help-Guide-1.pdf";
const hvRewiringHelpGuide =
  "/help-guides/harkins-and-vickers/HV-Rewiring-Help-Guide-1.pdf";
const hvSmokeAlarmHelpGuide =
  "/help-guides/harkins-and-vickers/Smoke-Alarm-Help-Guide-1.pdf";
const hvStairLightingHelpGuide =
  "/help-guides/harkins-and-vickers/Stair-Lighting-Help-Guide-FV.pdf";
const kgEicrHelpGuide = "/help-guides/keith-gunn/KGE-EICR-Help-Guide.pdf";
const kgRewiringHelpGuide = "/help-guides/keith-gunn/Rewiring-Help-Guide.pdf";
const kgSmokeAlarmHelpGuide =
  "/help-guides/keith-gunn/Smoke-Alarm-Help-Guide_KG.pdf";
const kgStairLightingHelpGuide = "/help-guides/keith-gunn/StairLighting_KG.pdf";

const isHarkinsAndVickers = window.location.href.includes("harkinsandvickers");

const email = isHarkinsAndVickers
  ? "connect@harkinsandvickers.co.uk"
  : "connect@keithgunn.co.uk";
const phone = isHarkinsAndVickers ? "0141-486-8899" : "0131-225-3205";

export default function Success() {
  const { setForwardStep, setBackStep, formType, infoFormReason } =
    useContext(AppContext);

  let pdfPath;
  if (isHarkinsAndVickers) {
    if (formType === REWIRING) {
      pdfPath = hvRewiringHelpGuide;
    } else if (formType === EICR) {
      pdfPath = hvEicrHelpGuide;
    } else if (formType === SMOKE_DETECTOR) {
      pdfPath = hvSmokeAlarmHelpGuide;
    } else if (formType === STAIR_LIGHTING) {
      pdfPath = hvStairLightingHelpGuide;
    }
  } else {
    if (formType === REWIRING) {
      pdfPath = kgRewiringHelpGuide;
    } else if (formType === EICR) {
      pdfPath = kgEicrHelpGuide;
    } else if (formType === SMOKE_DETECTOR) {
      pdfPath = kgSmokeAlarmHelpGuide;
    } else if (formType === STAIR_LIGHTING) {
      pdfPath = kgStairLightingHelpGuide;
    }
  }

  // Trigger a page view event in Google Tag Manager
  const triggerPageViewGTMEvent = () => {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: 'pageview',
      pagePath: window.location.href,
      pageTitle: document.title,
    });
    console.log(`pageview for ${document.title} at ${window.location.href}`);
  }

  const renderSuccessContentForService = () => {
    if (infoFormReason === COULD_NOT_FIND_TIMESLOT) {
      return (
        <>
          <p>
            We will be in touch shortly to help find a time which is suitable
            for you in order to carry out your {formType} job.
          </p>
        </>
      );
    }
    switch (formType) {
      case REWIRING:
        if (infoFormReason === REQUESTED_EMAILED_QUOTE) {
          return (
            <>
              <p>
                We will email you with an itemised quote for your rewiring.
                Should you wish to proceed, please email us at{" "}
                <a className="underline" href={`mailto:${email}`}>
                  {email}
                </a>
                .
              </p>
            </>
          );
        } else if (infoFormReason === FOUR_BEDROOM_SELECTED) {
          return (
            <>
              <p>
                We will be in touch shortly to confirm the details of your
                rewiring job.
              </p>
            </>
          );
        }
        return (
          <>
            <p>
              You have scheduled an appointment with our estimator. We will
              carry out an on-site survey of your property to ensure the
              information within your quote is accurate and talk through the
              rewiring process with you. Please note, this quote may require an
              update once we have surveyed your home.
            </p>
          </>
        );
      case CONSUMER_UNIT:
        if (infoFormReason === FOUR_BEDROOM_SELECTED) {
          return (
            <>
              <p>
                We will be in touch shortly to confirm the details of your
                consumer unit installation.
              </p>
            </>
          );
        }
        return (
          <>
            <p>
              You have scheduled an appointment for one of our electricians to
              perform a preliminary EICR (Electrical Installation Condition
              Report). This is to ensure that the installation meets the current
              standards and that there are no faults which could cause tripping
              of a new system.
            </p>
            <p>
              If the results of the EICR are satisfactory we will get in touch
              and book a follow up appointment to install the new consumer unit.
              If the results of the EICR were unsatisfactory, then one of our
              estimators will provide an updated quote for installing the new
              consumer unit which will include any remedial work that is
              required.
            </p>
          </>
        );
      case CARBON_MONOXIDE:
        if (infoFormReason === EXISTING_ALARMS_SELECTED) {
          return (
            <>
              <p>
                We will be in touch shortly to confirm the details of your
                carbon monoxide detector installation.
              </p>
            </>
          );
        }
        return (
          <>
            <p>
              You have scheduled an appointment for one of our electricians to
              attend on your desired date and time to carry out your carbon
              monoxide detector installation.
            </p>
          </>
        );
      case LED_LIGHTING:
        return (
          <>
            <p>
              You have scheduled an appointment for one of our electricians to
              attend on your desired date and time to carry out your LED
              lighting installation.
            </p>
          </>
        );
      case EICR:
        if (infoFormReason === FOUR_BEDROOM_SELECTED) {
          return (
            <>
              <p>
                We will be in touch shortly to confirm the details of your EICR
                job.
              </p>
            </>
          );
        }
        return (
          <>
            <p>
              You have scheduled an appointment for one of our electricians to
              attend. The EICR normally takes 2-3 hours depending on the
              property size.
            </p>
            <p>
              Once the EICR is completed, our NICEIC qualified supervisor will
              review the document, if the results of the EICR are satisfactory
              then we will sign it off. We aim for this to take 3 working days.
            </p>
            <p>
              If the results of your EICR are unsatisfactory, one of our
              estimators will be in touch with a new quotation to carry out
              necessary remedial work required in order to obtain a satisfactory
              EICR.
            </p>
          </>
        );
      case SMOKE_DETECTOR:
        if (infoFormReason === EXISTING_ALARMS_SELECTED) {
          return (
            <>
              <p>
                We will be in touch shortly to confirm the details of your smoke
                alarm detector installation.
              </p>
            </>
          );
        }
        return (
          <>
            <p>
              You have scheduled an appointment for one of our electricians to
              attend on your desired date and time to carry out your smoke
              detector installation.
            </p>
          </>
        );
      case STAIR_LIGHTING:
        if (infoFormReason === WRONG_LIGHT_FITTING) {
          return (
            <>
              <p>
                We will be in touch shortly to confirm the details of your stair
                lighting installation.
              </p>
            </>
          );
        }
        return (
          <>
            <p>
              You have scheduled an appointment for one of our electricians to
              attend on your desired date and time to carry out your stair
              lighting installation.
            </p>
          </>
        );
      default:
        return (
          <>
            <p>
              You have scheduled an appointment for one of our electricians to
              attend on your desired date and time to carry out your job.
            </p>
          </>
        );
    }
  };

  useEffect(() => {
    triggerPageViewGTMEvent(); // fire GTM page view event when this "page" is loaded
    setForwardStep(undefined);
    setBackStep(undefined);
  }, []);
  return (
    <div className="flex justify-center mt-8">
      <Card
        type="success"
        title="Booking Successful"
        content={
          <div className="flex flex-col gap-6">
            <div>
              <h2 className="text-lg font-semibold mb-4">Next Steps</h2>
              {renderSuccessContentForService()}
            </div>
            <div>
              <h2 className="text-lg font-semibold my-4">
                Need to get in touch?
              </h2>
              <p>
                Send us an email at{" "}
                <a className="underline" href={`mailto:${email}`}>
                  {email}
                </a>
              </p>
              <p>
                Give us a call on{" "}
                <a className="underline" href="tel:0131 225 3205">
                  {phone}
                </a>
              </p>
            </div>
          </div>
        }
        downloadPdf={pdfPath ? pdfPath : undefined}
      />
    </div>
  );
}
